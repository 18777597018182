<template>
    <div>
        <div class="content">
            <el-form :model="dataForm" ref="cmdRealDataForm" label-width="170px" :inline="true">
                <el-form-item label="电池电压返回到电池：" prop="batteryVoltageBackToBattery">
                    <el-select v-model="dataForm.batteryVoltageBackToBattery">
                        <el-option v-for="item in backToBatteryOptions" :key="item.value" :label="item.name" :value="item.value"/>
                    </el-select>
                </el-form-item>
            </el-form>
        </div>
        <div class="btn-box">
            <el-button @click="submit" type="primary">设置</el-button>
        </div>
    </div>
</template>

<script>
import loading from '@/utils/cmdLoading';
import global from '@/components/Global';
import message from '@/utils/message';
import mqttService from '@/api/mqtt-service';
import {queryEsaioManagerJsonSchemaDetail} from '@/api/jsonschema/jsonSchemaApi';
import jsonSchema from '@/api/jsonschema/json-schema';

export default {

    name: "battery-voltage-back-to-battery",
    data() {

        return {

            deviceSn: '',
            dataForm: {

                batteryVoltageBackToBattery: ''
            },
            command: {

                commandCodeR: 'get_rate_info',
                commandCodeW: 'set_battery_vol_back_to_battery',
            },
            backToBatteryOptions: [
                {
                    name : '0V',
                    value: '00.0'
                },
                {
                    name : '12V',
                    value: '12.0'
                },
                {
                    name : '12.3V',
                    value: '12.3'
                },
                {
                    name : '12.5V',
                    value: '12.5'
                },
                {
                    name : '12.8V',
                    value: '12.8'
                },
                {
                    name : '13V',
                    value: '13.0'
                },
                {
                    name : '13.3V',
                    value: '13.3'
                },
                {
                    name : '13.5V',
                    value: '13.5'
                },
                {
                    name : '13.8V',
                    value: '13.8'
                },
                {
                    name : '14V',
                    value: '14.0'
                },
                {
                    name : '14.3V',
                    value: '14.3'
                },
                {
                    name : '14.5V',
                    value: '14.5'
                },
                {
                    name : '24V',
                    value: '24.0'
                },
                {
                    name : '24.5V',
                    value: '24.5'
                },
                {
                    name : '25V',
                    value: '25.0'
                },
                {
                    name : '25.5V',
                    value: '25.5'
                },
                {
                    name : '26V',
                    value: '26.0'
                },
                {
                    name : '26.5V',
                    value: '26.5'
                },
                {
                    name : '27V',
                    value: '27.5'
                },
                {
                    name : '28V',
                    value: '28.0'
                },
                {
                    name : '28.5V',
                    value: '28.5'
                },
                {
                    name : '29V',
                    value: '29.0'
                },
                {
                    name : '48V',
                    value: '48.0'
                },
                {
                    name : '49V',
                    value: '49.0'
                },
                {
                    name : '50V',
                    value: '50.0'
                },
                {
                    name : '51V',
                    value: '51.0'
                },
                {
                    name : '52V',
                    value: '52.0'
                },
                {
                    name : '53V',
                    value: '53.0'
                },
                {
                    name : '54V',
                    value: '54.0'
                },
                {
                    name : '55V',
                    value: '55.0'
                },
                {
                    name : '56V',
                    value: '56.0'
                },
                {
                    name : '57V',
                    value: '57.0'
                },
                {
                    name : '58V',
                    value: '58.0'
                }
            ],
            jsonSchemaData: {}
        }
    },
    methods: {
        /**
         * 查询初始化.
         */
        initPage(deviceSn) {

            this.deviceSn = deviceSn;
            this.cmdLoading = loading.loading('电池电压返回到电池读取中...', 30);
            let that = this;
            let option = {

                loading: that.cmdLoading,
                data: {

                    deviceSn: that.deviceSn,
                    commandCode: that.command.commandCodeR
                },
                mqttMessageCallback: res => {

                    if (res.code === global.response_status_success_obj) {

                        that.initSuccessCallBack(res.data);
                    } else {

                        message.error('指令下发失败');
                    }
                }
            };
            setTimeout(() => {

                mqttService.disconnectMqtt();
            }, 30000);
            mqttService.sendEsaioBusinessCommand(option);
            // 查询jsonSchema
            this.queryWriteCommandJsonSchema();
        },
        /**
         * 设置提交.
         */
        submit() {

            let check = jsonSchema.validate(this.jsonSchemaData, this.dataForm);
            if (!check.pass) {

                message.error('参数错误');
                return;
            }
            let that = this;
            this.cmdLoading = loading.loading('设置电池电压返回到电池指令下发中...', 30);
            let option = {

                loading: that.cmdLoading,
                data: {

                    deviceSn: that.deviceSn,
                    commandCode: that.command.commandCodeW,
                    data: that.dataForm
                },
                mqttMessageCallback: res => {

                    if (res.code === global.response_status_success_obj) {

                        message.success('设置电池电压返回到电池成功');
                    } else {

                        message.error('指令下发失败');
                    }
                }
            };
            setTimeout(() => {

                mqttService.disconnectMqtt();
            }, 30000);
            mqttService.sendEsaioBusinessCommand(option);
        },
        /**
         * 查询写指令的的json schema校验信息
         */
        queryWriteCommandJsonSchema() {

            let param = {

                deviceSn: this.deviceSn,
                commandCode: this.command.commandCodeW
            };
            queryEsaioManagerJsonSchemaDetail(param).then((res) => {

                if (res.code === global.response_status_success_obj) {

                    this.jsonSchemaData = res.data;
                } else {

                    message.error(res.msg);
                }
            }).catch(err => {

                message.error('请求失败,请稍后再试');
                console.error(err);
            });
        },
        /**
         * 查询指令回调.
         */
        initSuccessCallBack(data) {

            this.dataForm.batteryVoltageBackToBattery = data.batteryReDischargeVoltage;
        }
    }
}
</script>

<style lang="scss" scoped>

.content {

    height: 490px;
    margin: 10px 0 1% 5%
}

.btn-box {

    text-align: center;
}
</style>